import { withSentry } from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteLoaderData,
} from "@remix-run/react";
import { LinksFunction, MetaFunction } from "@remix-run/node";
import rootCss from "./global.css?url";
import SnackbarProvider from "./components/snackbar-provider";
import rootLoader from "~/root-loader";
import { withEmotionCache } from "@emotion/react";
import React from "react";
import { unstable_useEnhancedEffect as useEnhancedEffect } from "@mui/material";

export { default as loader } from "./root-loader";
export { ErrorBoundary } from "./error";

export const meta: MetaFunction = () => {
  return [{ title: "Maestro" }, { name: "description", content: "Maestro" }];
};

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: rootCss },
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap",
  },
];

export interface ClientStyleContextData {
  reset: () => void;
}

export const ClientStyleContext = React.createContext<ClientStyleContextData>({
  reset: () => {},
});

export const Layout = withEmotionCache(
  ({ children }: { children: React.ReactNode }, emotionCache) => {
    const rootData = useRouteLoaderData<typeof rootLoader>("root");
    const SENTRY_DSN = rootData?.ENV.SENTRY_DSN;

    const clientStyleData = React.useContext(ClientStyleContext);

    // This piece of code is the one provided by Material-UI for Remix project that suffers
    // from document Hydration problems
    useEnhancedEffect(() => {
      emotionCache.sheet.container = document.head;
      const tags = emotionCache.sheet.tags;
      emotionCache.sheet.flush();
      tags.forEach((tag) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        emotionCache.sheet._insertTag(tag);
      });
      clientStyleData.reset();
    }, []);

    return (
      <html lang="fr">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="build" content="0190176b-ee85-742d-b915-01dac70c7119" />
          <title>Maestro</title>
          <Meta />
          <Links />
          {SENTRY_DSN ? (
            <script
              id="sentry-dsn-env-init"
              dangerouslySetInnerHTML={{
                __html: `window.ENV = ${JSON.stringify({
                  SENTRY_DSN,
                })}`,
              }}
            />
          ) : null}
        </head>
        <body>
          {children}
          <ScrollRestoration />
          <Scripts />
        </body>
      </html>
    );
  }
);

function App() {
  return (
    <SnackbarProvider>
      <Outlet />
    </SnackbarProvider>
  );
}

export default withSentry(App);
